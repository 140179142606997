import React from "react";
import classNames from "classnames";
import { useMobile, useGlobal } from "frontend/hooks";
import ResultsHeader from "./ResultsHeader";
import ResultsList from "./ResultsList";

import styles from "./Results.module.scss";
import Sponsors from "../Sponsors";

const Results = () => {
  const { view, filtersOpen } = useMobile();
  const { sponsors } = useGlobal();

  return (
    <section
      className={classNames(
        {
          [styles["results--filtersOpen"]]: filtersOpen,
          "is-hidden-mobile": filtersOpen,
        },
        styles.results
      )}
    >
      <ResultsHeader isHidden={view !== "map"} />
      {sponsors.locations.includes("results_top") && (
        <Sponsors isHidden={view !== "results" || filtersOpen} />
      )}
      <ResultsList isHidden={view !== "results" || filtersOpen} />
    </section>
  );
};

Results.propTypes = {};

export default Results;
