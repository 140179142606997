import React from "react";
import PropTypes from "prop-types";
import { getDirectionsLink } from "frontend/helpers";
import { locationShape } from "frontend/shapes";
import Distance from "../../Distance";
import AttributeList from "../../AttributeList";
import CategoriesList from "../../CategoriesList";
import { Button } from "../../ButtonOrLink";
import T from "../../Translate";

import styles from "./MapInfo.module.scss";

const MapInfo = ({ location }) => {
  const {
    id,
    name,
    distance,
    latitude,
    longitude,
    address,
    location_attributes: attributes,
    location_categories: categories,
  } = location;

  return (
    <div className={styles.mapinfo}>
      <h3 className={styles.mapinfo__name}>{name}</h3>
      <div className={styles.mapinfo__details}>
        {distance && <Distance value={distance} />}
        {attributes && <AttributeList attributes={attributes} />}
        <CategoriesList
          categories={categories}
          className={styles.mapinfo__categories}
          size="sm"
        />
        <div className={styles.mapinfo__buttons}>
          <Button
            href={getDirectionsLink({ latitude, longitude, address })}
            target="_blank"
          >
            <T translate="detail.directions_button" />
          </Button>
          <Button
            to={{
              pathname: `/locations/${id}`,
              search: window.location.search,
            }}
          >
            <T translate="results.more_information_button" />
          </Button>
        </div>
      </div>
    </div>
  );
};

MapInfo.propTypes = {
  location: PropTypes.shape(locationShape).isRequired,
};

MapInfo.displayName = "Map.Info";

export default MapInfo;
