import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useGlobal, useQueryFilters } from "frontend/hooks";
import { Dropdown } from "../form";
import { DEFAULT_LANG } from "frontend/i18n";

const SelectLanguage = () => {
  const { t, i18n } = useTranslation();
  const { languages } = useGlobal();
  const { query, updateQuery } = useQueryFilters();

  const handleOnChange = useCallback(
    (event) => {
      const value = event?.target?.value;

      i18n.changeLanguage(value);
      updateQuery({ ...query, lang: value });

      if (window.ga && typeof window.ga === "function") {
        window.ga("send", "event", {
          eventCategory: "Language",
          eventAction: "select-language",
          eventLabel: value,
        });
      }
    },
    [i18n, updateQuery, query]
  );

  return (
    <Dropdown
      id="selectLanguage"
      ariaLabel={t("menu.language_select_label")}
      items={languages}
      onChange={handleOnChange}
      defaultValue={query.lang ?? i18n.lang ?? DEFAULT_LANG}
    />
  );
};

SelectLanguage.propTypes = {};

export default SelectLanguage;
