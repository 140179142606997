const openList = [
  {
    id: "mon",
    label: "filters.mon_checkbox",
    value: 1,
  },
  {
    id: "tues",
    label: "filters.tues_checkbox",
    value: 2,
  },
  {
    id: "wed",
    label: "filters.wed_checkbox",
    value: 3,
  },
  {
    id: "thurs",
    label: "filters.thurs_checkbox",
    value: 4,
  },
  {
    id: "fri",
    label: "filters.fri_checkbox",
    value: 5,
  },
  {
    id: "sat",
    label: "filters.sat_checkbox",
    value: 6,
  },
  {
    id: "sun",
    label: "filters.sun_checkbox",
    value: 0,
  },
];

const openTimeList = [
  {
    id: "5pm",
    label: "filters.open_after_5_checkbox",
    value: 17,
  },
];

const distanceList = [
  {
    id: "any",
    label: "filters.distance_any_label",
    count: 0,
    value: "",
  },
];

for (var i = 5; i < 16; i = i + 5) {
  distanceList.push({
    id: `${i}miles`,
    label: `results.distance_unit`,
    count: i,
    value: i,
  });
}

distanceList.push({
  id: `30miles`,
  label: `results.distance_unit`,
  count: 30,
  value: 30,
});

const defaultFilters = {
  distance: 15,
  distanceUnits: "imperial",
};

export { openList, openTimeList, distanceList, defaultFilters };
