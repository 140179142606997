import { useState } from "react";
import { useSessionStorage } from "frontend/hooks";
import { userLocationApi } from "frontend/api";

function getLocationString({ latitude, longitude }) {
  if (!latitude || !longitude) return null;

  return `${latitude},${longitude}`;
}

const SESSION_NAME = "food-finder-location";

export default function useUserLocation() {
  const [state, setState] = useState({
    isLoading: false,
    isError: false,
  });

  const [locationString, setLocation] = useSessionStorage(SESSION_NAME, "");

  const handleError = (error) => {
    const message = "Error getting user location.";
    let reason = "";

    if (error.code === 1) {
      // eslint-disable-next-line max-len
      reason = `Permission denied. To see locations near you, please allow Food Finder to access your location.`;
    } else if (error.code === 2) {
      reason = `Position unavailable at this time.`;
    } else if (error.code === 3) {
      reason = "Location timeout.";
    }

    console.error(message, error);

    setState({
      isLoading: false,
      isError: true,
    });
  };

  const handleSuccess = ({ coords }) => {
    setState({
      isLoading: false,
      isError: false,
    });
    setLocation(getLocationString(coords));
  };

  const loadUserLocation = ({ onSuccess } = {}) => {
    setState({ isLoading: true, isError: false });
    userLocationApi({
      onSuccess: (data) => {
        handleSuccess(data);
        if (onSuccess) onSuccess(data);
      },
      onError: handleError,
      timeout: 7000,
    });
  };

  return { ...state, locationString, loadUserLocation };
}
