import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { filterShape } from "frontend/shapes";
import { MultiSelect } from "../../form";

import styles from "../Filters.module.scss";

const FiltersCategory = ({ categories, isCampaign, initValue }) => {
  const { t } = useTranslation();

  const handleChange = (event) => {
    const form = event.target?.form;

    if (form) {
      form.requestSubmit();
    }
  };

  return (
    <div className={styles.filters__category}>
      <label htmlFor="categoryDropdown" className={styles.filters__label}>
        {isCampaign
          ? t("campaign_location_category_label", { ns: "active_campaign" })
          : t("filters.category_label")}
      </label>
      <MultiSelect
        large
        id="categoryDropdown"
        items={categories}
        ariaLabel={t("filters.category_dropdown_aria_label")}
        onChange={handleChange}
        placeholder={t("filters.category_any_label")}
        disabled={!categories || categories.length === 0}
        name="category"
        initValue={initValue}
      />
    </div>
  );
};

FiltersCategory.propTypes = {
  onCategoryChange: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.shape(filterShape)),
  isCampaign: PropTypes.bool,
  initValue: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default FiltersCategory;
