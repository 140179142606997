import React from "react";
import PropTypes from "prop-types";
import { globalDataShape } from "frontend/shapes";

import { LocationsProvider } from "./Locations";
import { MobileProvider } from "./Mobile";
import { GlobalProvider } from "./Global";

const AppProvider = ({ children, globalData }) => (
  <GlobalProvider value={globalData}>
    <LocationsProvider>
      <MobileProvider>{children}</MobileProvider>
    </LocationsProvider>
  </GlobalProvider>
);

AppProvider.propTypes = {
  children: PropTypes.node,
  globalData: PropTypes.shape(globalDataShape),
};

export default AppProvider;
