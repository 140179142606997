import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { filterShape } from "frontend/shapes";
import AttributeFilter from "../../AttributeFilter";

import styles from "./FiltersAttributes.module.scss";

const FiltersAttributes = ({ attributes, initValue }) => {
  const { t } = useTranslation();

  if (!attributes) return null;

  return (
    <div className={styles.filtersattributes}>
      <fieldset>
        <legend className="sr-only">{t("filters.aria_header")}</legend>
        <ul className={styles.filtersattributes__list}>
          {attributes.map(({ labelKey, value, icon }, i) => (
            <li className={styles.filtersattributes__item} key={i}>
              <AttributeFilter
                labelKey={labelKey}
                value={value}
                icon={icon}
                name="attribute"
                initChecked={initValue ? initValue.includes(value) : false}
              />
            </li>
          ))}
        </ul>
      </fieldset>
    </div>
  );
};

FiltersAttributes.propTypes = {
  checkboxState: PropTypes.object,
  attributes: PropTypes.arrayOf(PropTypes.shape(filterShape)),
  initValue: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default FiltersAttributes;
