import React from "react";
import PropTypes from "prop-types";
import { useMobile } from "frontend/hooks";
import MapLocations from "./MapLocations";
import MapWrapper from "./MapWrapper";

const Map = ({ containerClass }) => {
  const { view, filtersOpen } = useMobile();

  return (
    <MapWrapper
      isHidden={view !== "map" || filtersOpen}
      containerClass={containerClass}
    >
      <MapLocations />
    </MapWrapper>
  );
};

Map.propTypes = {
  containerClass: PropTypes.string,
};

export default Map;
