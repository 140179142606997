/* eslint-disable camelcase */
import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useGlobal, useQueryFilters } from "frontend/hooks";
import { Button } from "frontend/components/ButtonOrLink";

import styles from "./MapLegend.module.scss";
import CategoriesList from "../../CategoriesList";

const MapLegend = forwardRef(({ loaded }, ref) => {
  const [hidden, setHidden] = useState(true);
  const { query } = useQueryFilters();
  const { categories: defaultCategories, activeCampaign } = useGlobal();
  const { t } = useTranslation();

  const onClick = () => {
    setHidden(!hidden);
  };

  const categories =
    query.campaign === "1"
      ? activeCampaign?.categories ?? []
      : defaultCategories ?? [];

  if (categories?.length === 0) return null;

  return (
    <div
      ref={ref}
      className={styles.mapLegend}
      aria-live="polite"
      data-loaded={!!loaded}
    >
      <div
        className={styles.mapLegend__list}
        id="legend"
        aria-hidden={hidden}
        aria-labelledby="legendButton"
      >
        <CategoriesList categories={categories} size="sm" showDescriptions />
      </div>
      <div
        id="legendButton"
        className={styles.mapLegend__button}
        aria-controls="legend"
      >
        <Button onClick={onClick} type="button">
          {t(hidden ? "map.show_legend" : "map.hide_legend")}
        </Button>
      </div>
    </div>
  );
});

MapLegend.displayName = "Map.Legend";

MapLegend.propTypes = {
  loaded: PropTypes.boolean,
};

export default MapLegend;
