import React, { useCallback } from "react";
import { useQueryFilters } from "frontend/hooks";
import { Button } from "../../ButtonOrLink";
import T from "../../Translate";

const ResetButton = () => {
  const { query, replaceQuery } = useQueryFilters();

  // Reset to user location & language
  const handleClick = useCallback(() => {
    const { lat, lng, lang } = query;
    replaceQuery({ lat, lng, lang });
  }, [query, replaceQuery]);

  return (
    <Button type="reset" onClick={handleClick} secondary>
      <T translate="filters.reset_filters_button" />
    </Button>
  );
};

export default ResetButton;
