import React, { createContext } from "react";
import PropTypes from "prop-types";
import { transformFilterArray } from "frontend/helpers";
import { globalDataShape } from "frontend/shapes";

export const GlobalContext = createContext();

// Global data from meta tags and data-props
export const GlobalProvider = ({ value, children }) => {
  const {
    categories,
    attributes,
    title,
    themeColor = "#3E9F0A",
    sponsors,
    activeCampaign,
    ...values
  } = value;

  // Transform categories and attributes
  const categoriesList = transformFilterArray(categories, "category");
  const attributesList = transformFilterArray(attributes, "attribute");
  const campaignCategoriesList = activeCampaign
    ? transformFilterArray(activeCampaign.location_categories, "category")
    : [];

  // Initial meta data
  const metaData = {
    title: `{{pre}}$t(header.site_header) - ${title}`,
    description: "{{pre}}$t(header.here_to_help_text)",
  };

  return (
    <GlobalContext.Provider
      value={{
        ...values,
        /* eslint-disable indent */
        ...(activeCampaign
          ? {
              activeCampaign: {
                categories: campaignCategoriesList,
                ...activeCampaign,
              },
            }
          : {}),
        /* eslint-enable indent */
        metaData,
        themeColor,
        categories: categoriesList,
        attributes: attributesList,
        ...(sponsors && {
          sponsors: {
            locations: sponsors.locations ?? ["results_top"],
            sponsors: sponsors.sponsors || [],
          },
        }),
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.shape(globalDataShape),
};
