import React from "react";
import ReactDOM from "react-dom";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "focus-visible";
import "ie11-custom-properties";
import { checkFlexGap } from "frontend/helpers";

import i18n, { DEFAULT_LANG } from "./i18n";
import "frontend/index.scss";

import App from "frontend/App";

function addi18nResources(localeObj, ns, keyName) {
  if (!localeObj || !ns || !keyName) return;

  Object.entries(localeObj).forEach(([key, value]) => {
    i18n.addResources(key, ns, {
      [keyName]: value,
    });
  });
}

document.addEventListener("DOMContentLoaded", () => {
  const mount = document.getElementById("frontend-mount");
  const languages = [];
  const params = new URLSearchParams(document.location.search);
  const lang = params.get("lang") ?? DEFAULT_LANG;

  if (lang && lang !== DEFAULT_LANG) {
    i18n.changeLanguage(lang);
  }

  // Get global data props
  const globalProps = JSON.parse(mount.dataset.props);

  // Add translations i18n namespace
  if (globalProps.translations) {
    const translations = globalProps.translations;
    // Setting deep = true and overwrite = true overwrites default translations
    Object.entries(translations).forEach(([key, value]) => {
      languages.push({ label: value.label, value: key });
      i18n.addResourceBundle(key, "translation", value.translation, true, true);
    });

    languages.sort((a, b) => {
      if (a.value === "en" || a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
  }

  // Add announcements to i18n namespace
  if (globalProps.announcement) {
    const { title, body } = globalProps.announcement;
    addi18nResources(title, "announcement", "title");
    addi18nResources(body, "announcement", "body");
  }

  // Add categories to i18n instance
  const {
    categories,
    attributes,
    activeCampaign,
    ...restGlobalProps
  } = globalProps;

  if (categories) {
    categories.forEach(({ id, label, description }) => {
      addi18nResources(label, "categories", `category_${id}`);
      addi18nResources(description, "categories", `category_${id}_description`);
    });
  }

  // Add attributes to i18n instance
  if (attributes) {
    attributes.forEach(({ id, label }) => {
      addi18nResources(label, "attributes", `attribute_${id}`);
    });
  }

  // Add active campaign to i18n instance
  if (activeCampaign) {
    const obj = activeCampaign;

    if (obj) {
      Object.keys(obj).forEach((key, i) => {
        addi18nResources(obj[key], "active_campaign", `campaign_${key}`);
      });

      obj.location_categories?.forEach(({ id, label, description }) => {
        addi18nResources(label, "categories", `category_${id}`);
        addi18nResources(
          description,
          "categories",
          `category_${id}_description`
        );
      });
    }
  }

  checkFlexGap();

  ReactDOM.render(
    <App
      globalData={{
        ...restGlobalProps,
        activeCampaign: activeCampaign,
        categories: categories,
        attributes: attributes,
        languages,
      }}
    />,
    mount
  );
});
