export const siteLink = "https://www.example.com";

export const missionLink = `${siteLink}/about-us/our-story/`;

export const nondiscriminationLink = `${siteLink}/`;

export const helpLink = `${siteLink}/find-help/faq`;

export default [
  {
    id: "assistance",
    textKey: "menu.assistance_link",
    href: helpLink,
    footerMenu: true,
  },
  {
    id: "give",
    textKey: "menu.give_link",
    href: `${siteLink}/give`,
    footerMenu: true,
  },
  {
    id: "volunteer",
    textKey: "menu.get_involved_link",
    href: `${siteLink}/get-involved`,
    footerMenu: true,
  },
  {
    id: "help",
    textKey: "menu.find_help_link",
    href: `${siteLink}/find-help/`,
    footerMenu: true,
  },
  {
    id: "about",
    textKey: "menu.about_us_link",
    href: `${siteLink}/about-us/`,
    footerMenu: true,
  },
  {
    id: "work",
    textKey: "menu.our_work_link",
    href: `${siteLink}/our-work/`,
    footerMenu: true,
  },
  {
    id: "mission",
    textKey: "menu.our_mission_link",
    href: missionLink,
  },
  {
    id: "nondiscrimination",
    textKey: "menu.non_discrimination_statement_link",
    href: nondiscriminationLink,
  },
];
