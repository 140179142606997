import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useUserLocation, useQueryFilters } from "frontend/hooks";
import Pin from "frontend/components/Icons/Pin";
import styles from "./UserLocationButton.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { isEmpty } from "frontend/helpers";
import { IconSpinner } from "frontend/components/Icons";

const UserLocationButton = ({ onClick, className }) => {
  const { t } = useTranslation();
  const { isLoading, locationString, loadUserLocation } = useUserLocation();
  const { query, updateQuery } = useQueryFilters();

  const isActive = useMemo(
    () =>
      !!(query?.lat && query?.lng) || (isEmpty(query.q) && !!locationString),
    [query.lat, query.lng, query.q, locationString]
  );

  const handleClick = () => {
    loadUserLocation({
      onSuccess: ({ coords }) => {
        updateQuery({
          lat: coords.latitude,
          lng: coords.longitude,
          q: null,
        });
      },
    });

    if (onClick) onClick();
  };

  return (
    <div className={classNames(className, styles.button)}>
      <button
        className={styles.button}
        name="location"
        onClick={handleClick}
        aria-pressed={isActive}
        type="button"
      >
        {isLoading ? (
          <IconSpinner size={20} />
        ) : (
          <Pin width={20} height={20} className={styles.icon} />
        )}
        <span className={styles.text}>{t("filters.use_current_location_or_search")}</span>
      </button>
    </div>
  );
};

UserLocationButton.propTypes = {
  onClick: PropTypes.function,
  className: PropTypes.string,
};

export default UserLocationButton;
