import React from "react";
import defaultProps from "./defaultProps";

export default function Pin(svgProps) {
  const props = {
    ...defaultProps,
    ...svgProps,
  };
  return (
    <svg width={21} height={28} fill="none" viewBox="0 0 21 28" {...props}>
      <path
        fillRule="evenodd"
        d="M16.294 4.366C14.756 2.915 12.67 2.1 10.498 2.1c-2.174 0-4.26.815-5.796 2.266C3.164 5.816 2.3 7.785 2.3 9.837c0 6.42 8.197 15.363 8.197 15.363s8.196-8.942 8.196-15.363c0-2.052-.863-4.02-2.4-5.471Zm-5.795 9.656a3.726 3.726 0 1 0 0-7.452 3.726 3.726 0 0 0 0 7.452Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
Pin.displayName = "SVG.Pin";
