import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import MapInfo from "../MapInfo";
import { locationShape } from "frontend/shapes";
import { useLocations } from "frontend/hooks";
import { LOCATION_ZOOM } from "frontend/shared/constants";
import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import ReactDOMServer from "react-dom/server";
import MapPin from "../MapPin";

const MapMarker = ({ location, clusterer }) => {
  const { markerId, setMarkerId, setPosition } = useLocations();

  const position = useMemo(
    () => ({ lat: location.latitude, lng: location.longitude }),
    [location]
  );

  const handleMarkerClick = useCallback(() => {
    setMarkerId(location.id);
    setPosition({ center: position, zoom: LOCATION_ZOOM });
  }, [setMarkerId, setPosition, location.id, position]);

  const handleOnClose = useCallback(() => {
    setMarkerId(0);
  }, [setMarkerId]);

  const iconUrl = useMemo(() => {
    const iconString = ReactDOMServer.renderToString(
      <MapPin location={location} />
    );
    return encodeURIComponent(iconString);
  }, [location]);

  if (typeof google === "undefined") return null;

  return (
    <>
      <MarkerF
        position={position}
        onClick={handleMarkerClick}
        icon={{
          url: `data:image/svg+xml,${iconUrl}`,
        }}
        clusterer={clusterer}
      >
        {markerId === location.id && (
          <InfoWindowF onCloseClick={handleOnClose} position={position}>
            <MapInfo location={location} />
          </InfoWindowF>
        )}
      </MarkerF>
    </>
  );
};

MapMarker.displayName = "Map.Marker";

MapMarker.propTypes = {
  location: PropTypes.shape(locationShape).isRequired,
  clusterer: PropTypes.any,
};

export default MapMarker;
