import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useLocations, useGlobal } from "frontend/hooks";
import MapLegend from "../MapLegend";

const MapWrapper = ({ children, isHidden, containerClass }) => {
  const { googleMaps, defaultMapMeta } = useGlobal();
  const [map, setMap] = useState();
  const legendRef = useRef(null);

  const {
    map: { locations },
    zoom,
    center,
    setPosition,
  } = useLocations();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMaps.key,
    language: "en",
  });

  const onLoad = useCallback((map) => {
    setMap(map);

    if (legendRef?.current) {
      // eslint-disable-next-line
      map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
        legendRef.current
      );
    }
  }, []);

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  // Keep current zoom in sync with zoom state
  const handleZoom = useCallback(() => {
    if (map) {
      const newZoom = map.getZoom();
      if (zoom && newZoom !== zoom) {
        setPosition({ zoom: newZoom });
      }
    }
  }, [zoom, setPosition, map]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (!map || !google) return;

    // eslint-disable-next-line no-undef
    const mapBounds = new google.maps.LatLngBounds();
    locations.map((location) => {
      if (!location.latitude || !location.longitude) return null;

      mapBounds.extend({
        lat: Number(location?.latitude),
        lng: Number(location?.longitude),
      });
    });

    map.fitBounds(mapBounds);
  }, [locations, map, setPosition]);
  
  return (
    <div
      className={classNames("is-hidden-print", containerClass, {
        "is-hidden-mobile": isHidden,
      })}
    >
      {isLoaded && (
        <GoogleMap
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onZoomChanged={handleZoom}
          mapContainerStyle={{
            height: "100%",
            width: "100%",
          }}
        >
          {children}
        </GoogleMap>
      )}
      <MapLegend ref={legendRef} loaded={!!map} />
    </div>
  );
};

MapWrapper.propTypes = {
  children: PropTypes.node,
  isHidden: PropTypes.bool,
  containerClass: PropTypes.string,
  language: PropTypes.string,
};

MapWrapper.displayName = "Map.Wrapper";

export default MapWrapper;
