import PropTypes from "prop-types";

const sponsorType = PropTypes.shape({
  id: PropTypes.number,
  logo: PropTypes.shape({
    path: PropTypes.string,
    content_type: PropTypes.string,
  }),
  alt_text: PropTypes.object,
  url: PropTypes.object,
});

export default {
  title: PropTypes.string,
  googleMaps: PropTypes.shape({
    key: PropTypes.string,
    mapId: PropTypes.string,
  }),
  categories: PropTypes.array,
  attributes: PropTypes.array,
  logo: PropTypes.shape({
    url: PropTypes.string,
    type: PropTypes.string,
  }),
  themeColor: PropTypes.string,
  sponsors: PropTypes.shape({
    locations: PropTypes.arrayOf(PropTypes.string),
    sponsors: PropTypes.arrayOf(sponsorType),
  }),
};
