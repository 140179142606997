import React from "react";
import PropTypes from "prop-types";

const IconChecked = ({ checked, ...otherProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...otherProps}
  >
    <path d="M12 16H4c-2.2 0-4-1.8-4-4V4c0-2.2 1.8-4 4-4h8c2.2 0 4 1.8 4 4v8c0 2.2-1.8 4-4 4zM4 1C2.3 1 1 2.3 1 4v8c0 1.7 1.3 3 3 3h8c1.7 0 3-1.3 3-3V4c0-1.7-1.3-3-3-3H4z" />
    <path
      d="M6.3 12L3 8.7l1.3-1.3 2 2L11.7 4 13 5.3 6.3 12z"
      style={{ opacity: 0 + checked }}
      data-check
    />
  </svg>
);

IconChecked.propTypes = {
  checked: PropTypes.bool.isRequired,
};

export default IconChecked;
