import format from "date-fns/format";
import { parseISO } from "date-fns";
import compareAsc from "date-fns/compareAsc";
import i18next from "i18next";
import getDateLocale from "./getDateLocale";

const so = {
  days: [],
};

function parseDateTime(dateStr) {
  return parseISO(dateStr);
}

function getDateTimeString(date, locale) {
  return format(date, date.getMinutes() === 0 ? "h a" : "p", {
    locale,
  }).toLowerCase();
}

function getDayHoursStrings(item, lang) {
  const { open_time: open, close_time: close, day, comment } = item;
  if (!open) return;
  const locale = getDateLocale(lang);

  const openDate = parseDateTime(open);
  const closeDate = close ? parseDateTime(close) : close;
  const dayString = i18next.t(`weekdays.${day}_label`);
  const openString = getDateTimeString(openDate, locale);
  const closeString = close ? getDateTimeString(closeDate, locale) : close;
  let hoursString = close ? `${openString} - ${closeString}` : openString;

  if (item.comment) hoursString = `<span>${hoursString}</span> (${comment})`;

  return {
    day,
    dayString,
    hoursString,
  };
}

export default function getFormattedHours(hours, lang) {
  // Sort by day value, then compare time values
  hours.sort(function (a, b) {
    return a.day - b.day === 0
      ? compareAsc(new Date(a.open_time), new Date(b.open_time))
      : a.day - b.day;
  });

  let lastDate = {};
  const reduced = [];

  hours.forEach((item, i) => {
    const date = getDayHoursStrings(item, lang);
    if (!date) return;

    const { dayString, hoursString } = date;

    if (dayString === lastDate.dayString) {
      reduced[reduced.length - 1].hoursString += `, ${hoursString}`;
    } else if (
      i === 0 ||
      hoursString !== lastDate.hoursString ||
      date.day - lastDate.day > 1
    ) {
      reduced.push({ dayString, hoursString, day: date.day });
    } else {
      reduced[reduced.length - 1].dayStringEnd = dayString;
    }

    lastDate = reduced[reduced.length - 1];
  });

  // Return as an array of strings
  return reduced.map(({ dayString, dayStringEnd, hoursString }) =>
    dayStringEnd
      ? `${dayString} - ${dayStringEnd}: ${hoursString}`
      : `${dayString}: ${hoursString}`
  );
}
