import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useMobile } from "frontend/hooks";
import { Button } from "../ButtonOrLink";
import { IconOpenClose } from "../Icons";

import styles from "./MobileToggle.module.scss";

const MobileToggle = ({ containerClass }) => {
  const { t } = useTranslation();
  const { filtersOpen, view, setView, toggleFiltersOpen } = useMobile();

  const values = ["results", "map"];

  return (
    <div
      className={classNames("is-hidden-print", styles.toggles, containerClass)}
      data-open={filtersOpen}
    >
      <Button
        onClick={toggleFiltersOpen}
        icon={<IconOpenClose isOpen={filtersOpen} />}
        aria-pressed={filtersOpen}
      >
        {filtersOpen
          ? t("filters.close_filters_label")
          : t("filters.open_filters_label")}
      </Button>
      <div
        className={styles.toggles__group}
        aria-label="Toggle between map and results"
        hidden={filtersOpen}
      >
        {values.map((value) => (
          <button
            key={value}
            type="button"
            aria-pressed={view === value}
            className={styles.toggles__option}
            onClick={() => setView(value)}
          >
            {t(`menu.${value}_button`)}
          </button>
        ))}
      </div>
    </div>
  );
};

MobileToggle.propTypes = {
  containerClass: PropTypes.string,
};

export default MobileToggle;
