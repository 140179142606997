import React, { useEffect, useRef, useState } from "react";
import { useLocations } from "frontend/hooks";
import { useTranslation } from "react-i18next";
import { Button } from "../../../ButtonOrLink";
import styles from "./LoadMore.module.scss";

const LoadMore = () => {
  const {
    pagination: { totalCount, page, totalPages },
    locations,
    fetchNextPage,
    isLoading,
  } = useLocations();
  const { t } = useTranslation();
  const ref = useRef();
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (!ref?.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin: "0px", threshold: 1.0 }
    );
    observer.observe(ref.current);
  }, []);

  useEffect(() => {
    if (isIntersecting && !isLoading && !disabled) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  const disabled = locations.length >= totalCount || page === totalPages;

  const handleClick = () => {
    fetchNextPage();
  };

  if (disabled) return null;

  return (
    <div className={styles.wrapper} ref={ref}>
      <Button onClick={handleClick} aria-disabled={isLoading} block>
        {isLoading ? t("common.loading") : t("common.load_more")}
      </Button>
    </div>
  );
};

export default LoadMore;
