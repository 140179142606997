import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { IconRadio } from "../../Icons";

import styles from "./Radio.module.scss";

const Radio = ({ children, ...radioProps }, ref) => {
  return (
    <label className={styles.radio}>
      <input
        type="radio"
        className={styles.radio__input}
        ref={ref}
        {...radioProps}
      />
      <IconRadio className={styles.radio__check} />
      <span className={styles.radio__label}>{children}</span>
    </label>
  );
};

Radio.propTypes = {
  children: PropTypes.node.isRequired,
};

export default forwardRef(Radio);
