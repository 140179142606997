import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { distanceList, defaultFilters } from "frontend/shared/filters";
import { Dropdown } from "../form";

import styles from "./Filters.module.scss";

const FiltersDistance = ({ initValue }) => {
  const { t } = useTranslation();

  const ref = useRef(null);

  const handleChange = (event) => {
    const form = ref.current?.form;

    if (form) {
      form.requestSubmit();
    }
  };

  // This field should reset to an empty string, not the inital value.
  useEffect(() => {
    if (!initValue || !ref.current) return;

    ref.current.value = initValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.filters__distance}>
      <label htmlFor="distanceDropdown" className={styles.filters__label}>
        {t("filters.distance_label")}
      </label>
      <Dropdown
        large
        id="distanceDropdown"
        items={distanceList}
        ariaLabel={t("filters.distance_dropdown_aria_label")}
        onChange={handleChange}
        defaultValue={defaultFilters.distance}
        name="distance"
        ref={ref}
      />
    </div>
  );
};

FiltersDistance.propTypes = {
  initValue: PropTypes.string,
};

export default FiltersDistance;
