import React from "react";
import PropTypes from "prop-types";
import { locationShape } from "frontend/shapes";
import { useLocations } from "frontend/hooks";
import MapMarker from "../MapMarker";
import { MarkerClustererF } from "@react-google-maps/api";

const MapLocations = () => {
  const {
    map: { locations },
  } = useLocations();

  if (!locations || locations.length === 0) return null;

  return (
    <MarkerClustererF maxZoom={14}>
      {(clusterer) =>
        locations.map((location, i) =>
          location.latitude && location.longitude ? (
            <MapMarker
              key={location.id}
              location={location}
              clusterer={clusterer}
            />
          ) : null
        )
      }
    </MarkerClustererF>
  );
};

MapLocations.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape(locationShape)),
};

MapLocations.displayName = "Map.Locations";

export default MapLocations;
