import React, { useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { usePopoverState, Popover, PopoverDisclosure } from "reakit/Popover";
import { useTranslation } from "react-i18next";
import { IconArrow, IconPin } from "../../Icons";
import Checkbox from "../Checkbox";
import { useQueryFilters } from "frontend/hooks";

import styles from "./Dropdown.module.scss";

const MultiSelect = ({
  ariaLabel,
  items,
  placeholder: initPlaceholder,
  disabled = false,
  initValue,
  ...checkboxProps
}) => {
  const popover = usePopoverState({ gutter: 0, placement: "bottom-start" });

  const { t } = useTranslation();

  const { query } = useQueryFilters();

  const inputRefs = useRef([]);

  const placeholder = useMemo(() => {
    const values = query[checkboxProps.name];

    // Get the current values and return placeholder text
    if (values?.length > 0) {
      return items
        .filter((item) => values.includes(`${item.value}`))
        .map((item) => t(item.labelKey, { ns: "categories" }))
        .join(", ");
    }

    return initPlaceholder;
  }, [query, checkboxProps.name, initPlaceholder, items, t]);

  // These fields should reset to false, not the inital value.
  useEffect(() => {
    if (!inputRefs.current || !initValue) return;

    inputRefs.current.forEach((ref) => {
      if (initValue.includes(ref.value)) {
        ref.checked = true;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.dropdown}>
      <PopoverDisclosure
        {...popover}
        className={styles.dropdown__select}
        {...(disabled ? { "aria-disabled": disabled } : {})}
      >
        <span
          className={classNames(
            "t-text-truncate",
            styles.dropdown__placeholder
          )}
        >
          {placeholder}
        </span>
        <IconArrow className={styles.dropdown__arrow} />
      </PopoverDisclosure>
      <Popover
        {...popover}
        aria-label={ariaLabel}
        className={styles.dropdown__menu}
        {...(disabled ? { "aria-disabled": disabled } : {})}
      >
        <ul>
          {items.map(({ labelKey, value, pinColor, icon }, i) => (
            <li className={styles.dropdown__menuItem} key={i}>
              <Checkbox
                value={value}
                ref={(el) => (inputRefs.current[i] = el)}
                {...checkboxProps}
              >
                <span className={styles.dropdown__checkboxLabel}>
                  <span className={styles.dropdown__checkboxText}>
                    {t(labelKey, { ns: "categories" })}
                  </span>
                  {pinColor && (
                    <IconPin
                      className={styles.dropdown__checkboxIcon}
                      color={pinColor}
                      icon={icon}
                      height={24}
                      width={24}
                    />
                  )}
                </span>
              </Checkbox>
            </li>
          ))}
        </ul>
      </Popover>
    </div>
  );
};

MultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  initValue: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default MultiSelect;
