import React from "react";
import PropTypes from "prop-types";
import idx from "idx";
import { locationShape } from "frontend/shapes";
import { useGlobal } from "frontend/hooks";
import { IconPin } from "frontend/components/Icons";

const MapPin = ({ location }) => {
  const global = useGlobal();

  const iconColor =
    idx(location.location_categories, (_) => _[0].pin_color) ||
    global?.themeColor;

  const icon = idx(location.location_categories, (_) => _[0].icon);

  return <IconPin color={iconColor} icon={icon} />;
};

MapPin.displayName = "Map.Pin";

MapPin.propTypes = {
  location: PropTypes.shape(locationShape).isRequired,
};

export default MapPin;
