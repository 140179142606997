import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { IconChecked } from "../../Icons";

import styles from "./Checkbox.module.scss";

const Checkbox = ({ children, value, ...props }, ref) => {
  return (
    <label className={styles.checkbox}>
      <input
        ref={ref}
        type="checkbox"
        className={styles.checkbox__input}
        value={value}
        {...props}
      />
      <IconChecked className={styles.checkbox__check} />
      <span className={styles.checkbox__label}>{children}</span>
    </label>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.bool,
};

export default forwardRef(Checkbox);
