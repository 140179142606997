import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { openList, openTimeList } from "../../../shared/filters";
import { Checkbox } from "../../form";

import styles from "./FiltersOpen.module.scss";
import filterStyles from "../Filters.module.scss";

const FiltersOpen = ({ initDateValue, initTimeValue }) => {
  const { t } = useTranslation();
  const timeRef = useRef(null);
  const dateRefs = useRef([]);

  function handleChange(event) {
    const form = event.target?.form;

    if (form) {
      form.requestSubmit();
    }
  }

  // These fields should reset to false, not the inital value.
  useEffect(() => {
    if (!timeRef.current || !dateRefs.current) return;
    timeRef.current.checked = initTimeValue;

    if (!initDateValue) return;

    dateRefs.current.forEach((ref) => {
      if (initDateValue.includes(ref.value)) {
        ref.checked = true;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <fieldset>
        <legend className={filterStyles.filters__label}>
          {t("filters.open_header")}
        </legend>
        <ul className={styles.filtersopen__list}>
          {openList.map((item, i) => (
            <li className={styles.filtersopen__item} key={item.id}>
              <Checkbox
                ref={(el) => (dateRefs.current[i] = el)}
                value={item.value}
                name="date"
                onChange={handleChange}
              >
                {t(item.label)}
              </Checkbox>
            </li>
          ))}
          {openTimeList.map((item) => (
            <li className={styles.filtersopen__item} key={item.id}>
              <Checkbox
                ref={timeRef}
                value={item.value}
                name="time"
                onChange={handleChange}
              >
                {t(item.label)}
              </Checkbox>
            </li>
          ))}
        </ul>
      </fieldset>
    </div>
  );
};

FiltersOpen.propTypes = {
  initDateValue: PropTypes.string,
  initTimeValue: PropTypes.string,
};

export default FiltersOpen;
