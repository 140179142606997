import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { IconArrow } from "../../Icons";

import styles from "./Dropdown.module.scss";

const Select = ({ id, ariaLabel, items, ...props }, ref) => {
  const { t } = useTranslation();

  if (!items || items.length === 0) return;

  return (
    <div className={styles.dropdown}>
      <select
        id={id}
        className={styles.dropdown__select}
        aria-label={ariaLabel}
        ref={ref}
        {...props}
      >
        {items.map((item, i) => (
          <option key={i} value={item.value}>
            {t(item.label, {
              ...(item.count ? { count: item.count } : {}),
            })}
          </option>
        ))}
      </select>
      <IconArrow className={styles.dropdown__arrow} />
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default forwardRef(Select);
