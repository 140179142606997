import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { locales } from "./locales";

export const DEFAULT_LANG = "en";

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: locales,
    defaultNS: "translation",
    returnEmptyString: true,
    interpolation: {
      escapeValue: false,
    },
    // fallback depending on user language
    fallbackLng: {
      zh: ["zh-Hans", DEFAULT_LANG],
      "zh-CN": ["zh-Hans", DEFAULT_LANG],
      "zh-TW": ["zh-Hant", DEFAULT_LANG],
      "zh-Hant": ["zh-Hans", DEFAULT_LANG],
      default: [DEFAULT_LANG],
    },
    react: {
      transSupportBasicHtmlNodes: true,
    },
  });

export default i18n;
