import React, {
  useReducer,
  useCallback,
  createContext,
  useRef,
  useMemo,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import idx from "idx";
import { locationsApi } from "frontend/api";
import {
  useQueryFilters,
  useDebounce,
  useUserLocation,
  useGlobal,
} from "frontend/hooks";
import { defaultFilters } from "frontend/shared/filters";

const DEFAULT_PAGINATION = {
  page: 1,
  totalCount: 0,
  totalPages: 0,
  perPage: 0,
};

function locationsReducer(state, action) {
  const { payload } = action;
  const locations = idx(payload, (_) => _.locations);
  const center = idx(payload, (_) => _.meta.map.center);
  const zoom = idx(payload, (_) => _.meta.map.zoom);
  const pagination = idx(payload, (_) => _.meta.pagination);

  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
        pagination: DEFAULT_PAGINATION,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        locations: locations || [],
        pagination: {
          page: pagination?.page || 1,
          totalCount: pagination?.total_count || 0,
          totalPages: pagination?.total_pages || 0,
          perPage: pagination?.count || 0,
        },
        center: center,
        zoom: zoom,
      };
    case "FETCH_PAGE_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        locations: [...state.locations, ...locations],
        pagination: {
          page: pagination?.page || 1,
          totalCount: pagination?.total_count || 0,
          totalPages: pagination?.total_pages || 0,
          perPage: pagination?.count || 0,
        },
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
        locations: [],
        pagination: DEFAULT_PAGINATION,
      };
    case "FETCH_PAGE_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case "UPDATE_POSITION":
      return {
        ...state,
        center: center || state.center,
        zoom: zoom || state.zoom,
      };
    case "UPDATE_MARKER":
      return {
        ...state,
        markerId: payload.markerId,
      };
    default:
      throw new Error();
  }
}

export const LocationsContext = createContext();

export const LocationsProvider = ({ children }) => {
  // Debounce the filter state
  const { query } = useQueryFilters();
  const debouncedQuery = useDebounce(query, 500, true);
  const { isLoading, locationString, loadUserLocation } = useUserLocation();
  const controllerRef = useRef(null);
  const { allLocations } = useGlobal();

  // Set the locations state
  const [state, dispatch] = useReducer(locationsReducer, {
    isLoading: false,
    isError: false,
    locations: allLocations,
    pagination: {
      ...DEFAULT_PAGINATION,
      totalCount: allLocations.length,
    },
  });

  // Set the map location state
  const [mapState, mapDispatch] = useReducer(locationsReducer, {
    isLoading: false,
    isError: false,
    locations: allLocations,
  });

  const apiParams = useMemo(() => {
    const userLocation =
      debouncedQuery.lat && debouncedQuery.lng
        ? `${debouncedQuery.lat},${debouncedQuery.lng}`
        : locationString;

    return {
      city_or_zip: debouncedQuery.q ?? null,
      user_location: debouncedQuery.q ? null : userLocation,
      active_campaign: debouncedQuery.campaign === "1" ? true : null,
      location_categories: debouncedQuery.category ?? null,
      open_days: debouncedQuery.date ?? null,
      open_at_time: debouncedQuery.time ?? null,
      distance: debouncedQuery.distance ?? defaultFilters.distance,
      distance_units:
        !debouncedQuery.q && userLocation ? defaultFilters.distanceUnits : null,
    };
  }, [debouncedQuery, locationString]);

  const apiParamsString = useMemo(() => JSON.stringify(apiParams), [apiParams]);

  // Get locations if the filter params have changed
  useEffect(() => {
    // If we're already loading locations, cancel request
    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    const handleSuccess = (res) => {
      mapDispatch({ type: "FETCH_SUCCESS", payload: res });
      dispatch({
        type: "FETCH_SUCCESS",
        payload: extractFullDataLocations(res),
      });
    };

    const handleError = (error) => {
      mapDispatch({ type: "FETCH_FAILURE", payload: error });
      dispatch({ type: "FETCH_FAILURE", payload: error });
    };

    dispatch({ type: "FETCH_INIT" });

    // Create a new controller for the new request
    const controller = new AbortController();
    const signal = controller.signal;
    controllerRef.current = controller;

    // Get locations
    locationsApi({
      params: { ...apiParams },
      onSuccess: handleSuccess,
      onError: handleError,
      signal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiParamsString]);

  const extractFullDataLocations = useCallback(({ locations, meta }) => {
    if (!meta.pagination) return locations;

    const start = (meta.pagination.page - 1) * meta.pagination.count;
    const end = meta.pagination.page * meta.pagination.count;
    return { locations: locations.slice(start, end), meta };
  });

  const fetchNextPage = useCallback(() => {
    const page = (state.pagination?.page || 1) + 1;

    const handleSuccess = (res) => {
      dispatch({ type: "FETCH_PAGE_SUCCESS", payload: res });
    };

    const handleError = (error) => {
      dispatch({ type: "FETCH_PAGE_FAILURE", payload: error });
    };

    // Get list locations
    locationsApi({
      params: { page, ...apiParams },
      onSuccess: handleSuccess,
      onError: handleError,
    });
  }, [state.pagination?.page, apiParams]);

  const setPosition = useCallback(
    ({ center, zoom }) => {
      const payload = {
        meta: { center, zoom },
      };
      dispatch({ type: "UPDATE_POSITION", payload });
    },
    [dispatch]
  );

  const setMarkerId = useCallback(
    (markerId) => {
      dispatch({ type: "UPDATE_MARKER", payload: { markerId } });
    },
    [dispatch]
  );

  const printFullData = useCallback(() => {
    const handleSuccess = (res) => {
      dispatch({ type: "FETCH_SUCCESS", payload: res });
      window.print();
    };

    const handleError = (error) => {
      dispatch({ type: "FETCH_FAILURE", payload: error });
    };

    dispatch({ type: "FETCH_INIT" });

    // Send either city_or_zip OR user_location
    const apiParams = {
      ...defaultFilters,
      ...debouncedQuery,
      full_data: true,
    };

    locationsApi({
      params: apiParams,
      onSuccess: handleSuccess,
      onError: handleError,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  // Load location on init
  useEffect(() => {
    if (apiParams.q || apiParams.lat || apiParams.lng || locationString) {
      return;
    }
    loadUserLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocationsContext.Provider
      value={{
        ...state,
        setPosition,
        setMarkerId,
        printFullData,
        fetchNextPage,
        map: mapState,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
};

LocationsProvider.propTypes = {
  children: PropTypes.node,
};
