import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ResetButton from "../form/ResetButton";
import MobileToggle from "../MobileToggle";
import T from "../Translate";
import FiltersSearch from "./FiltersSearch";
import FiltersCategory from "./FiltersCategory";
import FiltersDistance from "./FiltersDistance";
import FiltersOpen from "./FiltersOpen";
import FiltersAttributes from "./FiltersAttributes";
import { useTranslation } from "react-i18next";
import { useMobile, useGlobal, useQueryFilters } from "frontend/hooks";
import { filterShape } from "frontend/shapes";
import FiltersViewResults from "./FiltersViewResults";

import styles from "./Filters.module.scss";
import FiltersCampaign from "./FiltersCampaign";
import Form from "../form/Form";

const Filters = () => {
  const {
    categories: defaultCategories,
    attributes,
    activeCampaign,
  } = useGlobal();
  const { t } = useTranslation();
  const { filtersOpen } = useMobile();
  const { query } = useQueryFilters();
  // States
  const hasCampaign = !!activeCampaign;

  const isCampaign = useMemo(() => query.campaign === "1", [query.campaign]);

  const categories = useMemo(() => {
    const campaignCategories = activeCampaign?.categories ?? [];

    return isCampaign ? campaignCategories : defaultCategories;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCampaign]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initValues = useMemo(() => ({ ...query }), []);

  return (
    <Form
      aria-label={t("filters.aria_header")}
      className={classNames(styles.filters, "is-hidden-print")}
      id="filters"
    >
      <FiltersSearch initValue={initValues.q} />
      <MobileToggle containerClass={styles.filters__toggle} />
      <div
        className={classNames(
          {
            "is-hidden-mobile": !filtersOpen,
          },
          styles.filters__wrapper
        )}
      >
        <h3 className={styles.filters__header}>{t("filters.header")}</h3>
        {hasCampaign && (
          <div className={styles.filters__group}>
            <FiltersCampaign initValue={initValues.campaign} />
          </div>
        )}
        <div
          className={classNames(
            styles.filters__dropdowns,
            styles.filters__group
          )}
        >
          {categories && (
            <FiltersCategory
              categories={categories}
              isCampaign={isCampaign}
              initValue={initValues.category}
            />
          )}
          <FiltersDistance initValue={initValues.distance} />
        </div>
        <div className={styles.filters__group}>
          <FiltersOpen
            initDateValue={initValues.date}
            initTimeValue={initValues.time}
          />
        </div>
        {attributes && attributes.length > 0 && (
          <div className={styles.filters__group}>
            <FiltersAttributes
              attributes={attributes}
              initValue={initValues.attribute}
            />
          </div>
        )}
        <div
          className={classNames(styles.filters__reset, styles.filters__group)}
        >
          <ResetButton />
          <FiltersViewResults />
        </div>
        <div className={classNames(styles.filters__help, `is-hidden-print`)}>
          <T translate="filters.click_here_for_assistance" isHTML as="div" />
          <T translate="filters.click_here_for_assistance_2" isHTML as="div" />
          <T translate="filters.click_here_for_assistance_3" isHTML as="div" />
        </div>
      </div>
    </Form>
  );
};

Filters.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.shape(filterShape)),
  categories: PropTypes.arrayOf(PropTypes.shape(filterShape)),
};

export default Filters;
