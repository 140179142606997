import React from "react";
import { useMobile } from "frontend/hooks";
import T from "../Translate";

import styles from "./Header.module.scss";
import classNames from "classnames";

const Header = () => {
  const { searchOpen } = useMobile();

  return (
    <header
      className={classNames("a-bg-theme", styles.header)}
      aria-hidden={!searchOpen}
    >
      <h1 className="sr-only">
        <T translate="header.site_header" />
      </h1>
      <h2 className={styles.header__title}>
        <T translate="header.here_to_help_text" />
      </h2>
    </header>
  );
};

Header.propTypes = {};

export default Header;
