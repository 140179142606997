import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router } from "react-router-dom";
import Container from "frontend/components/Container";
import AnnouncementModal from "frontend/components/AnnouncementModal";
import { AppProvider } from "frontend/contexts";
import SEO from "frontend/components/SEO";
import { globalDataShape } from "frontend/shapes";

function App({ globalData }) {
  return (
    <Router>
      <AppProvider globalData={globalData}>
        <SEO />
        <AnnouncementModal {...globalData.announcement} />
        <Container />
      </AppProvider>
    </Router>
  );
}

App.propTypes = {
  globalData: PropTypes.shape(globalDataShape),
};

export default App;
