import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "../../ButtonOrLink";
import { IconArrow } from "../../Icons";
import { useLocations, useMobile } from "frontend/hooks";

import styles from "./FiltersSearch.module.scss";
import UserLocationButton from "./UserLocationButton";

const FiltersSearch = ({ initValue = "" }) => {
  const { t } = useTranslation();
  const { isLoading } = useLocations();
  const { searchOpen, toggleSearchOpen } = useMobile();
  const inputRef = useRef(null);

  const handleSearchToggle = (event) => {
    event.preventDefault();
    toggleSearchOpen();
  };

  const resetInput = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  // This field should reset to an empty string, not the inital value.
  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.value = initValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className={classNames("a-bg-theme", styles.filtersSearch)}
        aria-live="polite"
        data-open={searchOpen}
      >
        <UserLocationButton
          onClick={resetInput}
          className={styles.filtersSearch__location}
        />
        <div aria-hidden={!searchOpen}>
          <label htmlFor="searchInput" className="sr-only">
            {t("filters.city_or_zip_label")}
          </label>
          <div className={styles.filtersSearch__inputWrapper}>
            <input
              ref={inputRef}
              type="search"
              className={styles.filtersSearch__input}
              id="searchInput"
              size="5"
              placeholder={t("filters.city_or_zip_placeholder")}
              defaultValue=""
              name="q"
            />
            <Button type="submit" disabled={isLoading}>
              <span className={styles.filtersSearch__buttonLabel}>
                {t("filters.search_button")}
              </span>
            </Button>
          </div>
        </div>
      </div>
      <div
        hidden={searchOpen}
        className={classNames(styles.filtersSearch__searchAgain)}
      >
        <Button type="button" onClick={handleSearchToggle} theme block>
          <span>{t("filters.search_again_button")}</span>
          <IconArrow small />
        </Button>
      </div>
    </>
  );
};

FiltersSearch.propTypes = {
  initValue: PropTypes.string,
};

export default FiltersSearch;
